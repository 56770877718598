import * as isNode from 'detect-node';
import { Config } from '../constants/SharedConstants';
import { getAddressByGeoCode } from '../../organisms/SearchStore/SearchStoreUtilities/SearchStoreUtils';
import { checkLocationPermission } from 'common/utilities/phonepeSwitchAppUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { GOOGLE_SPOT_PRODUCTION } from '@kfc-global/react-shared/config/config.constants';
import AuthManager from '@kfc-global/react-shared/auth/AuthManager';

/**
 *
 * @class AManager
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 07/12/21 03:08 pm
 * @classdesc This class is an abstract class that stores logic that would otherwise be duplicated.
 */
class AManager {
  /**
   *
   * @type {Map<string, number | string | boolean | null | Record<string, any> |
   * {
   *  latitude: number,
   *  longitude: number
   *  }|
   * {
   *  gst: number,
   *  cgst: number,
   *  sgst: number,
   *  igst: number,
   *  cess: number
   *  }|
   * {
   *  type: string,
   *  tokenization: string,
   *  hash: string,
   *  protocol: string,
   *  status: string,
   *  total: string,
   *  transaction: string,
   *  reference: string}>}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:16 pm
   */
  static _data = new Map();
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 02:56 pm
   */
  static DEFAULT_HEADER = 'userAgent';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 02:56 pm
   */
  static DEFAULT_NULL = '-';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:38 pm
   */
  static DEFAULT_BUILD = '21.07.19.03.17.2';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:46 am
   */
  static KEY_CHANNEL = 'data.channel';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:46 am
   */
  static KEY_ADDRESS = 'data.address';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:46 am
   */
  static KEY_COORDINATES = 'data.coordinates';
  static KEY_COORDINATES_ERROR = 'data.coordinatesError';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:46 am
   */
  static KEY_EMAIL = 'data.email';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:46 am
   */
  static KEY_NAME = 'data.name';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_PHONE = 'data.phone';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_ORDER_ID = 'data.order.id';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_STORE_ID = 'data.store.id';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_BASKET_ID = 'data.basket.id';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_CUSTOMER_ID = 'data.customer.id';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 01:01 pm
   */
  static KEY_TRANSACTION_ID = 'data.transaction.id';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_TAXES = 'data.taxes';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_DISCOUNT = 'data.discount';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_TOTAL = 'data.total';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_SETUP_GOOGLE = 'setup.google';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static KEY_DEBUG = 'enable.debug';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 01:45 pm
   */
  static KEY_FAKE_SOLUTIONS = 'enable.fake.solutions';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 02:15 pm
   */
  static KEY_PAYMENT = 'data.payment';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 02:15 pm
   */
  static KEY_PAYMENT_STATUS_GOOGLE = 'data.payment.status.google';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:41 pm
   */
  static KEY_GOOGLE_PHONE_TOKEN = 'data.google.phone.token';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 02:59 pm
   */
  static KEY_OLO_PHONE_TOKEN = 'data.olo.phone.token';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 01:41 pm
   */
  static KEY_OLO_PAYMENT_INITIALIZER = 'data.olo.payment.initializer';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 11:52 am
   */
  static KEY_FEEDBACK_COUNT = 'data.feedback.counter';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 04:13 pm
   */
  static KEY_PAYMENT_OLO = 'status.payment.olo';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 04:13 pm
   */
  static KEY_PAYMENT_CHANNEL = 'status.payment.channel';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:28 am
   */
  static KEY_ORDER_OLO = 'status.order.olo';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:28 am
   */
  static KEY_ORDER_CHANNEL = 'status.order.channel';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:06 pm
   */
  static STORAGE_PHONE = 'storage.phone';
  /**
   *
   * @type {string}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:19 pm
   */
  static KEY_FEEDBACK = 'enable.feedback';

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 04:13 pm
   * @see paymentStatusOlo
   * @see paymentStatusChannel
   */
  static get paymentStatusOlo() {
    if (!this._data.has(this.KEY_PAYMENT_OLO)) {
      this._data.set(this.KEY_PAYMENT_OLO, false);
    }

    return this._data.get(this.KEY_PAYMENT_OLO);
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 04:13 pm
   * @see paymentStatusOlo
   * @see paymentStatusChannel
   */
  static get paymentStatusChannel() {
    if (!this._data.has(this.KEY_PAYMENT_CHANNEL)) {
      this._data.set(this.KEY_PAYMENT_CHANNEL, false);
    }

    return this._data.get(this.KEY_PAYMENT_CHANNEL);
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:28 am
   * @see orderStatusOlo
   * @see orderStatusChannel
   */
  static get orderStatusOlo() {
    if (!this._data.has(this.KEY_ORDER_OLO)) {
      this._data.set(this.KEY_ORDER_OLO, false);
    }

    return this._data.get(this.KEY_ORDER_OLO);
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:28 am
   * @see orderStatusOlo
   * @see orderStatusChannel
   */
  static get orderStatusChannel() {
    if (!this._data.has(this.KEY_ORDER_CHANNEL)) {
      this._data.set(this.KEY_ORDER_CHANNEL, false);
    }

    return this._data.get(this.KEY_ORDER_CHANNEL);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 01:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   * @see transactionId
   */
  static get transactionId() {
    return this._data.get(this.KEY_TRANSACTION_ID);
  }

  /**
   *
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 01:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   * @see transactionId
   */
  static set transactionId(value) {
    this._data.set(this.KEY_TRANSACTION_ID, value);
  }

  static set coordinates(value) {
    this._data.set(this.KEY_COORDINATES, value);
  }

  static set coordinatesError(value) {
    this._data.set(this.KEY_COORDINATES_ERROR, value);
  }

  /**
   *
   * @return {{
   *  type: string,
   *  tokenization: string,
   *  hash: string,
   *  protocol: string,
   *  status: string,
   *  total: string,
   *  transaction: string,
   *  reference: string,
   *  }|null}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:01 pm
   */
  static get payment() {
    return this._data.get(this.KEY_PAYMENT);
  }

  /**
   *
   * @param value {{
   *  type: string,
   *  tokenization: string,
   *  hash: string,
   *  protocol: string,
   *  status: string,
   *  total: string,
   *  transaction: string,
   *  reference: string,
   *  }|null}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:01 pm
   */
  static set payment(value) {
    this._data.set(this.KEY_PAYMENT, value);
  }

  /**
   * Sets Google Payment Request Status
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:01 pm
   */
  static get paymentStatusGoogle() {
    return this._data.get(this.KEY_PAYMENT_STATUS_GOOGLE);
  }

  /**
   * Returns Google Payment Request Status
   * @param value: string
   * @return string
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:01 pm
   */
  static set paymentStatusGoogle(value) {
    if (value?.paymentMethodData?.tokenizationData?.token) {
      const signatureData = JSON.parse(value.paymentMethodData.tokenizationData.token);
      if (signatureData?.signedMessage) {
        const signatureMsg = JSON.parse(signatureData.signedMessage);
        this._data.set(this.KEY_PAYMENT_STATUS_GOOGLE, signatureMsg?.paymentMethodDetails?.status);
      }
    }
  }

  /**
   * Sets Google Payment Request Status
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:01 pm
   */
  static get shouldGoogleSpotIgnoreOloFailure() {
    if (ChannelManager.isGoogle) {
      const isSpotProduction = config(GOOGLE_SPOT_PRODUCTION);
      return isSpotProduction === 'false' && ChannelManager.paymentStatusGoogle === Config.Status.Pass;
    }
    return false;
  }

  /**
   *
   * @return {string}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:43 pm
   */
  static get googlePhoneToken() {
    return this._data.get(this.KEY_GOOGLE_PHONE_TOKEN);
  }

  /**
   *
   * @param value {string}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:43 pm
   */
  static set googlePhoneToken(value) {
    this._data.set(this.KEY_GOOGLE_PHONE_TOKEN, value);
  }

  /**
   *
   * @return {Record<string, number|string>}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 02:59 pm
   */
  static get oloPhoneToken() {
    return this._data.get(this.KEY_OLO_PHONE_TOKEN);
  }

  /**
   *
   * @param value {Record<string, number|string>}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 02:59 pm
   */
  static set oloPhoneToken(value) {
    this._data.set(this.KEY_OLO_PHONE_TOKEN, value);
  }

  /**
   *
   * @return {Record<string, any>}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 01:41 pm
   */
  static get oloPaymentInitializer() {
    return this._data.get(this.KEY_OLO_PAYMENT_INITIALIZER);
  }

  /**
   *
   * @param value {Record<string, any>}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 01:41 pm
   */
  static set oloPaymentInitializer(value) {
    this._data.set(this.KEY_OLO_PAYMENT_INITIALIZER, value);
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:19 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @example Now you can enjoy the majesty of channel manager for
   * testing purposes by enabling or disabling testing and all the functionality will be turned
   * on or off automatically.
   */
  static enableFeedback() {
    this._data.set(this.KEY_FEEDBACK, true);
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:19 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @example Now you can enjoy the majesty of channel manager for
   * testing purposes by enabling or disabling testing and all the functionality will be turned
   * on or off automatically.
   */
  static disableFeedback() {
    this._data.set(this.KEY_FEEDBACK, false);
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 01:51 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see enabledFakeSolution
   * @see disableFakeSolution
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @see isFakeSolutionEnabled
   */
  static enabledFakeSolution() {
    this._data.set(this.KEY_FAKE_SOLUTIONS, true);
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 01:51 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @see isFakeSolutionEnabled
   */
  static disableFakeSolution() {
    this._data.set(this.KEY_FAKE_SOLUTIONS, false);
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 04:09 pm
   */
  static isGoogleSetup = () => {
    return this._data.get(this.KEY_SETUP_GOOGLE) ?? false;
  };

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 04:09 pm
   */
  static isPhoneSetup = () => {
    return ChannelManager.isGoogle ? !!window?.microapps : true;
  };

  /**
   *
   * @return {boolean} Returns boolean indicating if testing is overrides are enabled or disabled
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 04:09 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   */
  /* istanbul ignore next */
  static isDebugEnabled = () => {
    return ChannelManager._data.get(this.KEY_DEBUG) ?? false;
  };

  /**
   *
   * @return {boolean} Returns boolean indicating if feedback is enabled or disabled
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:19 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   */
  /* istanbul ignore next */
  static isFeedbackEnabled = () => {
    return this._data.get(this.KEY_FEEDBACK) ?? false;
  };

  /**
   *
   * @return {boolean} Returns boolean indicating if feedback is enabled or disabled
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/19/21 01:51 pm
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @see isFakeSolutionEnabled
   */
  /* istanbul ignore next */
  static isFakeSolutionEnabled = () => {
    return this._data.get(this.KEY_FAKE_SOLUTIONS) ?? false;
  };

  /**
   *
   * @return {number}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/13/21 11:56 am
   */
  static feedbackCount = () => {
    if (!this._data.get(this.KEY_FEEDBACK_COUNT)) {
      this._data.set(this.KEY_FEEDBACK_COUNT, 0);
    }

    this._data.set(this.KEY_FEEDBACK_COUNT, this._data.get(this.KEY_FEEDBACK_COUNT) + 1);

    return this._data.get(this.KEY_FEEDBACK_COUNT);
  };

  /**
   *
   * @param feedback {string|Error}
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:16 pm
   */
  /* istanbul ignore next */
  static addFeedback = feedback => {
    if (ChannelManager.isDebugEnabled() || this.isFeedbackEnabled()) {
      const paragraph = document.createElement('p');
      let text;

      if (typeof feedback === 'string') {
        text = document.createTextNode(`${this.feedbackCount()}: ${feedback}`);
      } else if (feedback instanceof Error) {
        text = document.createTextNode(`${this.feedbackCount()}: ${feedback.stack}`);
        paragraph.style.borderRadius = '0.3125rem'; // rem calc function = 5px
        paragraph.style.backgroundColor = '#ff00001a'; // light red background
      }

      paragraph.appendChild(text);
      document.getElementById('test-feedback')?.appendChild(paragraph);
      console.log('[[ CM ]]', feedback);
    }
  };
}

/**
 *
 * @class ChannelManager
 * @extends AManager
 * @author Isaac Ewing
 * @version 1.0.0 06/15/21 05:10 pm
 * @classdesc This class manages all the stuff needed and used that pertains to the viewport
 * specific classes and types.
 */
export class ChannelManager extends AManager {
  /**
   *
   * @readonly
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 06/15/21 05:10 pm
   */
  microapps;

  /**
   *
   * @return {string}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/16/21 03:54 pm
   * @version 1.0.1 06/17/21 10:52 am - changed to force return value to always return a valid string
   */
  static get channel() {
    if (!this._data.has(this.KEY_CHANNEL)) {
      this.BindUserAgent();
    }

    return this._data.get(this.KEY_CHANNEL);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/30/21 12:28 pm
   * @see address
   * @see coordinates
   */
  static get address() {
    return this._data.get(this.KEY_ADDRESS);
  }

  /**
   *
   * @return {{latitude: number, longitude: number} | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/30/21 12:28 pm
   * @see address
   * @see coordinates
   */
  static get coordinates() {
    return this._data.get(this.KEY_COORDINATES);
  }
  static get coordinatesError() {
    return this._data.get(this.KEY_COORDINATES_ERROR);
  }
  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/02/21 11:51 am
   * @see name
   * @see email
   * @see phone
   */
  static get name() {
    return this._data.get(this.KEY_NAME);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/02/21 11:52 am
   * @see name
   * @see email
   * @see phone
   */
  static get email() {
    return this._data.get(this.KEY_EMAIL);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/30/21 12:28 pm
   * @see name
   * @see email
   * @see phone
   */
  static get phone() {
    const cachedPhone = localStorage.getItem(this.STORAGE_PHONE) ?? this._data.get(this.KEY_PHONE);
    if (cachedPhone) {
      localStorage.setItem(this.STORAGE_PHONE, cachedPhone);
    }
    return cachedPhone;
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 06:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   */
  static get orderId() {
    if (this.isFakeSolutionEnabled()) {
      this.addFeedback(`Channel Manager is OVERRIDING order id with ${Config.Debug.Google.BasketId}`);

      return Config.Debug.Google.OrderId;
    }

    return this._data.get(this.KEY_ORDER_ID);
  }

  /**
   *
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 06:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   */
  static set orderId(value) {
    this._data.set(this.KEY_ORDER_ID, value);
  }

  /**
   *
   * @return {number | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 06:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   */
  static get storeId() {
    return this._data.get(this.KEY_STORE_ID);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 06:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   */
  static get basketId() {
    if (this.isFakeSolutionEnabled()) {
      this.addFeedback(`Channel Manager is OVERRIDING basket id with ${Config.Debug.Google.BasketId}`);

      return Config.Debug.Google.BasketId;
    }

    return this._data.get(this.KEY_BASKET_ID);
  }

  /**
   *
   * @return {string | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 06:01 pm
   * @see orderId
   * @see storeId
   * @see basketId
   * @see customerId
   */
  static get customerId() {
    return this._data.get(this.KEY_CUSTOMER_ID);
  }

  /**
   *
   * @return {{
   * gst : number,
   * cgst: number,
   * sgst: number,
   * igst: number,
   * cess: number,
   * }}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 05:36 pm
   * @see taxes
   * @see discount
   * @see total
   */
  static get taxes() {
    if (!this._data.get(this.KEY_TAXES)) {
      const taxes = {
        gst: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        cess: 0,
      };

      this._data.set(this.KEY_TAXES, taxes);
    }

    return this._data.get(this.KEY_TAXES);
  }

  /**
   *
   * @return {number}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 05:36 pm
   * @see taxes
   * @see discount
   * @see total
   */
  static get discount() {
    return this._data.get(this.KEY_DISCOUNT) ?? 0;
  }

  /**
   *
   * @return {number}
   * @readonly
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/06/21 05:36 pm
   * @see taxes
   * @see discount
   * @see total
   */
  static get total() {
    return this._data.get(this.KEY_TOTAL) ?? 0;
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/16/21 03:58 pm
   * @see isBrowser
   * @see isGoogle
   * @see isPhonePe
   * @see isPayTm
   */
  static get isBrowser() {
    return this.channel === Config.VIEWPORT_CHANNEL_BROWSER;
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/16/21 03:58 pm
   * @see isBrowser
   * @see isGoogle
   * @see isPhonePe
   * @see isPayTm
   */
  static get isGoogle() {
    return this.channel === Config.VIEWPORT_CHANNEL_GOOGLE;
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/16/21 03:58 pm
   * @see isBrowser
   * @see isGoogle
   * @see isPhonePe
   * @see isPayTm
   */
  static get isPhonePe() {
    return this.channel === Config.VIEWPORT_CHANNEL_PHONE_PE;
  }

  /**
   *
   * @return {boolean}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/16/21 03:58 pm
   * @see isBrowser
   * @see isGoogle
   * @see isPhonePe
   * @see isPayTm
   */
  static get isPayTm() {
    return this.channel === Config.VIEWPORT_CHANNEL_PAY_TM;
  }

  /**
   *
   * @return {boolean | null}
   * @readonly
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 04:32 pm
   * @see status
   */
  static get status() {
    if (ChannelManager.isGoogle) {
      return this.paymentStatusOlo;
    }

    return null;
  }

  /**
   *
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:33 pm
   * @see setForBrowser
   * @see setForGoogle
   * @see setForPhonePe
   * @see setForPayTm
   */
  static setForGoogle = () => {
    ChannelManager._data.set(ChannelManager.KEY_CHANNEL, Config.VIEWPORT_CHANNEL_GOOGLE);
    document.body.classList.add(Config.VIEWPORT_CLASSNAME_GOOGLE);
  };

  /**
   *
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:33 pm
   * @see setForBrowser
   * @see setForGoogle
   * @see setForPhonePe
   * @see setForPayTm
   */
  static setForPhonePe = () => {
    ChannelManager._data.set(ChannelManager.KEY_CHANNEL, Config.VIEWPORT_CHANNEL_PHONE_PE);
    document.body.classList.add(Config.VIEWPORT_CLASSNAME_PHONE_PE);
  };

  /**
   *
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:33 pm
   * @see setForBrowser
   * @see setForGoogle
   * @see setForPhonePe
   * @see setForPayTm
   */
  static setForPayTm = () => {
    ChannelManager._data.set(ChannelManager.KEY_CHANNEL, Config.VIEWPORT_CHANNEL_PAY_TM);
    document.body.classList.add(Config.VIEWPORT_CLASSNAME_PAY_TM);
  };

  /**
   *
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 03:33 pm
   * @see setForBrowser
   * @see setForGoogle
   * @see setForPhonePe
   * @see setForPayTm
   */
  static setForBrowser = () => {
    ChannelManager._data.set(ChannelManager.KEY_CHANNEL, Config.VIEWPORT_CHANNEL_BROWSER);
    document.body.classList.add(Config.VIEWPORT_CLASSNAME_BROWSER);
  };

  /**
   *
   * @param initiatePaymentResponse {Record<string, any>}
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 01:49 pm
   */
  static parseInitializer = initiatePaymentResponse => {
    this.oloPaymentInitializer = initiatePaymentResponse;
    this.transactionId = initiatePaymentResponse?.allowedPaymentMethods?.[0].parameters?.transactionId;
  };

  /**
   *
   * @return {void}
   * @static
   * @protected
   * @author Isaac Ewing
   * @version 1.0.0 07/16/21 04:12 pm
   */
  static bindGooglePaySpotJS = () => {
    if (!this.isGoogleSetup()) {
      let interval;
      const script = document.createElement('script');
      const checkMicroApps = () => {
        this.addFeedback('Checking micro apps: ' + (window?.microapps ? 'Pass' : 'Fail'));

        if (window?.microapps) {
          this.addFeedback(`Interval cleared: ${interval}`);
          clearInterval(interval);
        }
      };

      script.src = Config.Setting.Google.Source;
      document.body.appendChild(script);
      this.addFeedback('the google pay spot script has been added to the dom...');
      this._data.set(this.KEY_SETUP_GOOGLE, true);

      try {
        if (!window?.microapps) {
          interval = setInterval(checkMicroApps, Config.Setting.Interval);
          this.addFeedback(`Interval added: ${interval}`);
        }
      } catch (exception) {
        // this.addFeedback(exception);
        console.log('Exception in function bindGooglePaySpotJS.');
      }
    }
  };

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/25/21 11:36 am
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @example Now you can enjoy the majesty of channel manager for
   * testing purposes by enabling or disabling testing and all the functionality will be turned
   * on or off automatically.
   */
  /* istanbul ignore next */
  static enableGoogleDebug() {
    this._data.set(this.KEY_ADDRESS, Config.Debug.Google.Address);
    this._data.set(this.KEY_COORDINATES, Config.Debug.Google.Coordinates);
    this._data.set(this.KEY_NAME, Config.Debug.Google.Name);
    this._data.set(this.KEY_EMAIL, Config.Debug.Google.Email);
    this._data.set(this.KEY_PHONE, Config.Debug.Google.Phone);
    this.enableDebug();
    this.enableFeedback();
    this.setForGoogle();
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/25/21 11:36 am
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @example Now you can enjoy the majesty of channel manager for
   * testing purposes by enabling or disabling testing and all the functionality will be turned
   * on or off automatically.
   */
  /* istanbul ignore next */
  static enableDebug() {
    this._data.set(this.KEY_DEBUG, true);
    document.body.classList.add(Config.VIEWPORT_CLASSNAME_DEBUG);
  }

  /**
   *
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/25/21 11:36 am
   * @see enableGoogleDebug
   * @see disableDebug
   * @see enableFeedback
   * @see disableFeedback
   * @see isDebugEnabled
   * @see isFeedbackEnabled
   * @example Now you can enjoy the majesty of channel manager for
   * testing purposes by enabling or disabling testing and all the functionality will be turned
   * on or off automatically.
   */
  /* istanbul ignore next */
  static disableDebug() {
    this._data.set(this.KEY_DEBUG, false);
    document.body.classList.remove(Config.VIEWPORT_CLASSNAME_DEBUG);
  }

  /**
   *
   * @param {Record<string, string>} [headers=null] The header params list
   * @param {boolean} [enableDebug=null] A shortcut to enable debug mode
   * @return {void}
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/15/21 05:10 pm
   * @version 1.1.0 06/28/21 01:44 pm - added support for enable debug parameter
   * @see BindUserAgent
   * @see BindLocation
   */
  static BindUserAgent(headers = null, enableDebug = false) {
    if (enableDebug) {
      ChannelManager.enableGoogleDebug();
      this._data.delete(this.KEY_CHANNEL);
    }
    if (!this._data.get(this.KEY_CHANNEL)) {
      let agent;

      if (headers) {
        agent = `${headers?.[this.DEFAULT_HEADER] ?? null}`.toLowerCase();
      }
      if (!isNode) {
        /* istanbul ignore next */
        agent = agent ?? `${window?.navigator?.userAgent ?? null}`.toLowerCase();
      }

      if (agent?.includes(Config.VIEWPORT_AGENT_GOOGLE.toLowerCase())) {
        this.setForGoogle();
      } else if (agent?.includes(Config.VIEWPORT_AGENT_PHONE_PE.toLowerCase())) {
        this.setForPhonePe();
      } else if (agent?.includes(Config.VIEWPORT_AGENT_PAY_TM.toLowerCase())) {
        this.setForPayTm();
      } else {
        this.setForBrowser();
      }
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 06/30/21 01:13 pm
   * @version 1.1.0 06/30/21 05:29 pm - simplified into compatible async / await with internal promise wrapper
   * @see BindUserAgent
   * @see BindLocation
   */
  static async BindLocation() {
    //this has to be called each time to ensure accurate data is being returned
    if (ChannelManager.isGoogle) {
      await GoogleManager.Coordinates();
      await GoogleManager.Address();
    } else if (ChannelManager.isPhonePe) {
      await PhonePeManager.Coordinates();
      await PhonePeManager.Address();
    } else {
      await OloManager.Coordinates();
      await OloManager.Address();
    }
  }

  /**
   *
   * @return {Promise<string | null>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/01/21 02:38 pm
   */
  static async BindPhone() {
    //this has to be called each time to ensure accurate data is being returned
    if (ChannelManager.isGoogle) {
      await GoogleManager.Phone();
    }

    return this.phone;
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/02/21 11:32 am
   */
  static async BindUser(isLoggedIn) {
    //this has to be called each time to ensure accurate data is being returned
    if (ChannelManager.isGoogle) {
      await GoogleManager.User();
      await OloManager.User();
    } else if (ChannelManager.isPhonePe) {
      await PhonePeManager.User(isLoggedIn);
    }
  }

  /**
   *
   * @param initiatePaymentResponse {Record<string, any>}
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/02/21 02:17 pm
   */
  static async BindPayment(initiatePaymentResponse) {
    this.parseInitializer(initiatePaymentResponse);

    if (ChannelManager.isGoogle) {
      this.addFeedback(`Channel manager is about to call google manager - payment...`);
      await GoogleManager.Payment();
      this.addFeedback(`Channel manager has finished calling google manager - payment...`);
    } else {
      this.addFeedback(`Channel manager has chosen to SKIP payment...`);
    }

    this.addFeedback('Looks like the await finished for bind payment');
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/08/21 10:37 am
   */
  static async BindOrder() {
    if (ChannelManager.isGoogle) {
      await GoogleManager.Order();
      await OloManager.Order();
    }
  }
}

/**
 *
 * @class OloManager
 * @extends AManager
 * @author Isaac Ewing
 * @version 1.0.0 07/12/21 04:59 pm
 * @classdesc This class manages all the subsidiary logic related to the Olo payment gateway.
 * This was migrated into its own class to ease scalability as well as provide a realistic
 * dependability on maintenance and refactoring.
 */
class OloManager extends AManager {
  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:11 pm
   */
  static async Coordinates() {
    this.addFeedback(`Channel manager has chosen to use coordinates from olo - ${ChannelManager.channel}...`);

    if (navigator?.geolocation) {
      this.coordinates = null;
      try {
        const buildPromise = options => {
          return new Promise((pass, fail) =>
            navigator.geolocation.getCurrentPosition(pass ?? null, fail ?? null, options),
          );
        };
        const optionsVal = {
          enableHighAccuracy: Config.Setting.Accuracy,
          timeout: Config.Setting.Timeout,
          maximumAge: Config.Setting.Expiration,
        };
        const position = await buildPromise(optionsVal);
        const coordinates = {
          longitude: position?.coords?.longitude ?? null,
          latitude: position?.coords?.latitude ?? null,
        };
        this.coordinates = coordinates;
        this._data.set(this.KEY_COORDINATES, coordinates);
        this.addFeedback(
          `Channel manager is saving coordinates from olo - ${ChannelManager.channel}... ${ChannelManager.coordinates}`,
        );
      } catch (exception) {
        this.coordinatesError = exception;
        /* istanbul ignore next */
        this.addFeedback(`Channel manager hit an exception coordinates from olo - ${ChannelManager.channel}...`);
        /* istanbul ignore next */
        this.addFeedback(exception);
      }
    } else {
      /* istanbul ignore next */
      this.addFeedback(`Channel manager has chosen to SKIP coordinates from olo - ${ChannelManager.channel}...`);
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:19 pm
   */
  static async Address() {
    if (ChannelManager.coordinates) {
      const forGoogle = {
        lat: ChannelManager.coordinates.latitude,
        lng: ChannelManager.coordinates.longitude,
      };
      const address = await getAddressByGeoCode(forGoogle);

      this._data.set(this.KEY_ADDRESS, address);
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:51 am
   * @description This is only here because there are no ways to implement interfaces in JavaScript
   * realistically, so this empty function serves as a placeholder so the manager classes will be
   * uniform.
   */
  static async User() {
    this.addFeedback(`Channel manager has chosen to use user from olo - ${ChannelManager.channel}...`);
    this.addFeedback(
      `Channel manager is saving user name from olo - ${ChannelManager.channel}... ${ChannelManager.name}`,
    );
    this.addFeedback(
      `Channel manager is saving user email from olo - ${ChannelManager.channel}... ${ChannelManager.email}`,
    );
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:35 am
   * @description This is only here because there are no ways to implement interfaces in JavaScript
   * realistically, so this empty function serves as a placeholder so the manager classes will be
   * uniform.
   */
  static async Order() {
    this.addFeedback(`Channel manager has chosen to use order from olo - ${ChannelManager.channel}...`);
    this._data.set(this.KEY_ORDER_OLO, true);
    this.addFeedback(
      `Channel manager is saving order status olo - ${ChannelManager.channel}... ${this.orderStatusOlo}`,
    );
  }
}

/**
 *
 * @class GoogleManager
 * @extends AManager
 * @author Isaac Ewing
 * @version 1.0.0 07/12/21 03:16 pm
 * @classdesc This class manages all the subsidiary logic related to the Google payment gateway.
 * This was migrated into its own class to ease scalability as well as provide a realistic
 * dependability on maintenance and refactoring.
 */
class GoogleManager extends AManager {
  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:02 pm
   */
  static async Coordinates() {
    this.addFeedback(`Channel manager has chosen to use coordinates from google - ${ChannelManager.channel}...`);

    if (window?.microapps) {
      try {
        this._data.set(this.KEY_COORDINATES, await window.microapps?.getCurrentLocation());
      } catch (exception) {
        this.addFeedback(`Channel manager hit an exception coordinates from google - ${ChannelManager.channel}...`);
        this.addFeedback(exception);
      }
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:23 pm
   * @description This is only here because there are no ways to implement interfaces in JavaScript
   * realistically, so this empty function serves as a placeholder so the manager classes will be
   * uniform.
   */
  static async Address() {
    /* istanbul ignore next */
    this.addFeedback(`Channel manager has chosen to use address from google - ${ChannelManager.channel}...`);
    await OloManager.Address();
    /* istanbul ignore next */
    this.addFeedback(
      `Channel manager is saving address from google - ${ChannelManager.channel}... ${ChannelManager.address}`,
    );
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 12:26 pm
   */
  static async Phone() {
    this.addFeedback(`Channel manager has chosen to use phone from google - ${ChannelManager.channel}...`);

    if (window?.microapps) {
      try {
        const request = {
          nonce: Config.Setting.Google.Nonce,
          skipPrompt: !Config.Setting.Google.Prompt,
        };
        this.addFeedback(`Channel manager is about to call google - phone api...`);
        this.addFeedback(
          `Channel manager is sending phone request to google - ${ChannelManager.channel}... ${JSON.stringify(
            request,
          )}`,
        );

        const response = await window.microapps?.getPhoneNumber(request);
        const token = JSON.parse(atob(response.split('.')[1]));
        const phone = token?.phone_number?.split(' ')[1];

        localStorage.setItem(this.STORAGE_PHONE, phone);
        this.googlePhoneToken = response;
        this._data.set(this.KEY_PHONE, phone);
        this.addFeedback(
          `Channel manager is receiving phone request from google - ${ChannelManager.channel}... ${response}`,
        );
        this.addFeedback(`Phone number ${phone}...`);
        this.addFeedback(`Phone number saved...`);
      } catch (exception) {
        this.addFeedback(`Channel manager hit an exception phone from google - ${ChannelManager.channel}...`);
        this.addFeedback(exception);

        if (ChannelManager.isDebugEnabled()) {
          this.googlePhoneToken = Config.Debug.Google.Token.Phone;
          this.addFeedback(
            `Channel manager is OVERRIDING phone for google - ${ChannelManager.channel}... ${this.googlePhoneToken}`,
          );
        }
      }
    } else {
      /* istanbul ignore next */
      this.addFeedback(`Channel manager has chosen to SKIP phone from google - ${ChannelManager.channel}...`);
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/14/21 11:44 am
   */
  static async User() {
    this.addFeedback(`Channel manager has chosen to use user from google - ${ChannelManager.channel}...`);

    if (window?.microapps) {
      try {
        const request = {
          nonce: Config.Setting.Google.Nonce,
          skipPrompt: !Config.Setting.Google.Prompt,
        };
        const response = await window.microapps?.getIdentity(request);
        const token = JSON.parse(atob(response.split('.')[1]));
        const name = token?.name;
        const email = token?.email;

        this._data.set(this.KEY_NAME, name);
        this._data.set(this.KEY_EMAIL, email);
        this.addFeedback(`Channel manager identity debug data ${response}...`);
        this.addFeedback(`User name ${name}...`);
        this.addFeedback(`User email ${email}...`);
        this.addFeedback(`User name and email saved...`);
      } catch (exception) {
        this.addFeedback(`Channel manager hit an exception user from google - ${ChannelManager.channel}...`);
        this.addFeedback(exception);
      }
    } else {
      /* istanbul ignore next */
      this.addFeedback(`Channel manager has chosen to SKIP user from google - ${ChannelManager.channel}...`);
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:46 pm
   */
  static async Payment() {
    this.addFeedback(`Channel manager has chosen to use payment from google - ${ChannelManager.channel}...`);

    if (window?.microapps) {
      try {
        this.addFeedback(`Channel manager is sending request... ${JSON.stringify(this.oloPaymentInitializer)}`);

        const response = await window.microapps?.requestPayment(this.oloPaymentInitializer);
        const result = JSON.stringify(response);
        this.addFeedback(`Channel manager received google payment response... ${result}`);

        this.paymentStatusGoogle = response;
        this.payment = result;

        this.addFeedback(
          `Channel manager is saving payment status ${ChannelManager.channel}... ${this.paymentStatusGoogle}`,
        );
        this.addFeedback(`Channel manager payment debug data ${response}...`);
        this.addFeedback(`Channel manager has active payment data... ${JSON.stringify(this.payment)}`);
      } catch (exception) {
        this.addFeedback(`Channel manager hit an exception payment from google - ${ChannelManager.channel}...`);
        this.addFeedback(exception);
        this.payment = exception;

        if (ChannelManager.isDebugEnabled()) {
          const response = Config.Debug.Google.Payment.Response;
          this.paymentStatusGoogle = response;
          this.payment = JSON.stringify(response);

          this._data.set(this.KEY_PAYMENT_CHANNEL, true);
          this.addFeedback(
            `Channel manager is OVERRIDING payment for google - ${ChannelManager.channel}... ${this.paymentStatusChannel}`,
          );
        }
      }
    } else {
      /* istanbul ignore next */
      this.addFeedback(`Channel manager has chosen to SKIP payment from google - ${ChannelManager.channel}...`);
    }
  }

  /**
   *
   * @return {Promise<void>}
   * @async
   * @static
   * @public
   * @author Isaac Ewing
   * @version 1.0.0 07/12/21 03:46 pm
   */
  static async Order() {
    this.addFeedback(`Channel manager has chosen to use order from google - ${ChannelManager.channel}...`);

    if (window?.microapps) {
      try {
        const logoImg = document.querySelector('[alt="KFC LOGO"]');
        const request = {
          title: `Transaction: ${ChannelManager.transactionId}`,
          subtitle: `Order: ${ChannelManager.orderId}`,
          imageUrl: `https://${window?.location?.host}${logoImg?.src}`,
          total: { currency: 'INR', value: this.oloPaymentInitializer?.transactionInfo?.totalPrice ?? 0 },
          status: { type: 'COMPLETED' },
          payments: [
            {
              id:
                this.oloPaymentInitializer?.allowedPaymentMethods?.[0].parameters?.transactionReferenceId ??
                `ref-${new Date().getTime()}`,
            },
          ],
          merchant: { id: 'KFC' },
        };

        this.addFeedback(
          `Channel manager is sending ${ChannelManager.channel} order request... ${JSON.stringify(request)}`,
        );
        const response = await window.microapps?.createOrder(request);
        this._data.set(this.KEY_ORDER_CHANNEL, true);
        this.oloPaymentInitializer = null;
        this.addFeedback(
          `Channel manager is receiving ${ChannelManager.channel} order request... ${JSON.stringify(response)}`,
        );
        this.addFeedback(
          `Channel manager is saving order status ${ChannelManager.channel}... ${this.orderStatusChannel}`,
        );
      } catch (exception) {
        this.oloPaymentInitializer = null;
        this.addFeedback(`Channel manager hit an exception from ${ChannelManager.channel} order request...`);
        this.addFeedback(exception);

        if (ChannelManager.isDebugEnabled()) {
          this._data.set(this.KEY_ORDER_CHANNEL, true);
          this.addFeedback(
            `Channel manager is OVERRIDING order status ${ChannelManager.channel}... ${this.orderStatusChannel}`,
          );
        }
      }
    } else {
      /* istanbul ignore next */
      this.addFeedback(`Channel manager has chosen to SKIP from google - ${ChannelManager.channel}...`);
    }
  }
}

/**
 *
 * @type {{
 * AManager: AManager,
 * OloManager: OloManager,
 * GoogleManager: GoogleManager,
 * ChannelManager: ChannelManager,
 * }}
 * @public
 * @author Isaac Ewing
 * @version 1.0.0 07/29/21 03:18 pm
 * @desc This property is added specifically for testing and <em>SHOULD NOT</em> be used for any
 * other reason. This was exported and created this way specifically because JavaScript does not
 * have native support for different access levels like in TypeScript so this constant is needed
 * to emulate accessibility in OOP while allowing for unit testing.
 */
export const UnitTest = {
  AManager,
  OloManager,
  GoogleManager,
  ChannelManager,
};
class PhonePeManager extends AManager {
  static async Coordinates() {
    this.addFeedback(`Channel manager has chosen to use coordinates from phonepe - ${ChannelManager.channel}...`);

    if (await checkLocationPermission()) {
      await OloManager.Coordinates();
    } else {
      console.log('User did not provide location permission');
    }
  }

  static async Address() {
    this.addFeedback(`Channel manager has chosen to use address from phonepe - ${ChannelManager.channel}...`);
    await OloManager.Address();
    this.addFeedback(
      `Channel manager is saving address from phonepe - ${ChannelManager.channel}... ${ChannelManager.address}`,
    );
  }

  static async User(isUserLoggedIn) {
    if (isUserLoggedIn) {
      const { firstName: name, email, phoneNumber: phone } = AuthManager.getInstance();
      this._data.set(this.KEY_NAME, name);
      this._data.set(this.KEY_EMAIL, email);
      this._data.set(this.KEY_PHONE, phone);
    } else {
      return Promise.resolve();
    }
  }
}
