const START_ORDER_CONSTANTS = {
  INITIAL_DISPOSITION_DATA: 'initialDispositionData',
  DISPOSITION_DATA: 'dispositionData',
  SEARCH_ORDER_DATA: 'searchOrderData',
  SCHEDULE_ORDER_DATA: 'scheduleOrderData',
  SELECTED_TILE_DATA: 'SelectedTileData',
  DISPOSITION_METHOD: 'dispositionMethod',
  SEARCH_ORDER_USER_ADDRESS: 'userAddress',
  SCHEDULE_ORDER_TIME_DATA: 'scheduleOrderSelectedDateTime',
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  SCHEDULE_ORDER_TIME_PRESERVE_DATE: 'scheduleOrderPreserveDate',
  SCHEDULE_ORDER_TIME_PRESERVE_TIME: 'scheduleOrderPreserveTime',
  TYPE_DELIVERY: 'DELIVERY',
  TYPE_TAKE_WAY: 'PICKUP',
  TYPE_DINE_IN: 'DINE-IN',
  PICK_UP: 'pickup',
  TAKE_AWAY: 'takeaway',
  DINE_IN: 'dine-in',
  MENU_URL: '/menu',
  PDP_URL: '/pdp',
  SET_DISPOSITION: 'SET_DISPOSITION',
  SET_DISPOSITION_NEW: 'SET_DISPOSITION_NEW',
  CART_STATES_DATA: 'CART_STATES_DATA',
  CONFIRM_UPSIZE_ANALYTICS: 'CONFIRM_UPSIZE_ANALYTICS',
  LANGUAGE_SELECTOR: 'LANGUAGE_SELECTOR',
  LANGUAGE_UPDATED: 'LANGUAGE_UPDATED',
  ADD_TO_CART_CLICK: 'ADD_TO_CART_CLICK',
  USER_LOCALIZED: 'USER_LOCALIZED',
  PLP_PDP_DETAILS: 'PLP_PDP_DETAILS',
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  ANALYTICS_LOCALISATION_SIGNIN: 'localisationSignIn',
  AUTO: 'auto',
  SHOW_CLEAR_CART_ALERT: 'SHOW_CLEAR_CART_ALERT',
  CLEAR_CART_CONFIRM_CANCEL_MODAL: 'CLEAR_CART_CONFIRM_CANCEL_MODAL',
  clearCartChangeFlag: 'FromChange',
  orderTypeAlertTitle: 'OrderType',
  searchStoreAlertTitle: 'SearchStore',
  clearCartExposedFlag: 'FromExposed',
  CURRENT_STORE_INFO: 'currentStoreInfo',
  MINI_PDP_DATA: 'MINI_PDP_DATA',
  FROMFLAGEDIT: 'fromEdit',
  FROMFLAGPLP: 'fromPLP',
  EDIT_CLICKED: 'EDIT_CLICKED',
  INDIA: 'INDIA',
  HIDDEN_TEXT: 'hiddenText',
  TENDER_DATA: 'TENDER_DATA',
  PAYMENET_FORM_PAYLOAD: 'PAYMENET_FORM_PAYLOAD',
  ORDER_LOOKUP: 'Order Lookup',
  SHOW_CART_ERROR_MODAL: 'SHOW_CART_ERROR_MODAL',
  CART_DATA_ERROR_RESET: 'CART_DATA_ERROR_RESET',
  SET_FORCERELOAD: 'SET_FORCERELOAD',
  SHOW_LOCATION_CONFIRM_MODAL: 'SHOW_LOCATION_CONFIRM_MODAL',
  START_ORDER_CLICK: 'START_ORDER_CLICK',
  ORDER_MODE_BROWSE_MENU_CLICK: 'ORDER_MODE_BROWSE_MENU_CLICK',
  RESET_DELIVERY_ADDRESS_LINE: 'RESET_DELIVERY_ADDRESS_LINE',
  NOT_WITHIN_OPERATING_HOURS: 'NOT_WITHIN_OPERATING_HOURS',
  SET_ELIGIBLE_STORE: 'SET_ELIGIBLE_STORE',
  FAV_STORE_NOT_IN_RADIUS: 'FAV_STORE_NOT_IN_RADIUS',
  AUSTRALIA: 'Australia',
  SET_MULTI_PRODUCT_VIEW_MODAL: 'SET_MULTI_PRODUCT_VIEW_MODAL',
  UPDATE_CATERING_RETAIL_MENU_ALERT: 'UPDATE_CATERING_RETAIL_MENU_ALERT',
  SET_FIND_A_KFC_VIEW: 'SET_FIND_A_KFC_VIEW',
  SET_ASAP_SCHEDULING: 'SET_ASAP_SCHEDULING',
  SET_ORDER_NOW: 'SET_ORDER_NOW',
  SET_SCHEDULE_ORDER: 'SET_SCHEDULE_ORDER',
  SET_ORDER_NOW_REORDER: 'SET_ORDER_NOW_REORDER',
  SET_SCHEDULE_ORDER_REORDER: 'SET_SCHEDULE_ORDER_REORDER',
  SET_REDIRECTION_PATH: 'SET_REDIRECTION_PATH',
  IS_DEEPLINK_URL: 'IS_DEEPLINK_URL',
  STORE_NOT_ALIVE: 'STORE_NOT_ALIVE',
  DOORDASH_NOT_SERVICEABLE: 'DOORDASH_NOT_SERVICEABLE',
  REORDER_MODAL_OPEN: 'REORDER_MODAL_OPEN',
  FROM_REORDER_MODAL_OPEN: 'FROM_REORDER_MODAL_OPEN',
  REORDER_STORE_CHANGED: 'REORDER_STORE_CHANGED',
  MENU_NAV_ERROR: 'MENU_NAV_ERROR',
  UPDATE_TIMEBASED_MENU_DATA: 'UPDATE_TIMEBASED_MENU_DATA',
  UPDATE_TIMEBASED_MENU_ALERT: 'UPDATE_TIMEBASED_MENU_ALERT',
  CART_REVIEW_ORDER_MODAL: 'CartReviewOrderModel',
  REVIEW_ORDER_MODAL: 'ReviewOrderModel',
  MENU_CHANGE_MODAL: 'MenuChangeModal',
  MENU_CHANGE_WARNING_MODAL: 'MenuChangeWarningModal',
  PDP_UNAVAILABLE_MODAL: 'PDPUnavailableModal',
  PICK_UP_TIME: 'PICK UP TIME',
  DELIVERY_TIME: 'DELIVERY TIME',
  SCHEDULE_MODAL_OPEN: 'SCHEDULE_MODAL_OPEN',
  PLACE_ORDER_BUTTON: 'PLACE_ORDER_BUTTON',
  ORDER_SUMMARY_MODAL_CLOSE: 'ORDER_SUMMARY_MODAL_CLOSE',
  CHANGE_SCHEDULE_MODE: 'CHANGE_SCHEDULE_MODE',
  SCHEDULE_ORDER_CLICKED: 'SCHEDULE_ORDER_CLICKED',
  SHOW_SCHEDULE_DISPOSITION: 'SHOW_SCHEDULE_DISPOSITION',
  TIME_BASED_CETEGORY: 'timeBasedCetegory',
  FOODLINES: 'foodLines',
  FLATRESPONSE: 'flatResponse',
  CATEGORIES: 'categories',
  TIME_EQUAL_EXPIRED: 'timeExpiredAndEqual',
  RECENT_DYNAMIC_MENU_ALERT_DETAILS: 'recentDynamicMenuAlertDetails',
  PAPER_BAG_FEE: 'Paper Bag Fee'
};

export default START_ORDER_CONSTANTS;
