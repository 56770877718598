const SEARCH_STORE_CONSTANTS = {
  MIN_SEARCH_CHARACTER_LIMIT: 2,
  CONFIRM_BUTTON_TEXT: 'confirm',
  VIEW_MAP_BUTTON_TEXT: 'viewMap',
  SUUGESTED_ITEMS_TEXT: 'suggestedSearchTerms',
  ALLOWED_SUGGESTION_LIST: ['locality', 'political', 'postal_code', 'geocode', 'point_of_interest', 'establishment'],
  AUTO_SUGGESTIONS_PICKUP_FILTER: ['(regions)'],
  AUTO_SUGGESTIONS_DELIVERY_FILTER: ['address'],
  ALREADY_SERACHED_LOCATIONS: 'seachedLocations',
  SUGGESTION_ACTIVE_COLOR: '#F8F7F5',
  SUGGESTION_ACTIVE_OPACITY: '0.9',
  COLOR_BLACK: 'black',
  BRAND_BLACK: '#202124',
  COUNTRY_CODE: 'IN', // country code for search
  COUNTRY_CODE_INDIA: 'IN', // country code for India
  FULFILLED_TEXT: 'fulfilled',
  TYPE_DELIVERY: 'DELIVERY',
  TYPE_TAKE_WAY: 'PICKUP',
  DINE_IN: 'DINE IN',
  PICK_UP: 'pickup',
  TAKE_AWAY: 'Pick up',
  OK_STATUS: 'OK',
  DELIVERY_TIME_LIMIT_MINUTES: 30,
  STORE_CARD_LIMIT: 10,
  SERVICE_CHANNEL: 'web',
  SERVICE_CHANNEL_APP: 'app',
  STORE_MESSAGE: 'storeMessage',
  TYPE: '@type',
  LOCATION_FEATURE_SPECIFICATION: 'LocationFeatureSpecification',
  NAME: 'name',
  TENANT_ID: '15895bb59f7b4bb588ee933f8cd5344a',
  TENATNT_SESSION_ID: '9b1deb434ff32h4jv23f4v2g3hj4',
  STORE_STATUS_OPEN: 'OPEN',
  STORE_STATUS_CLOSED: 'closed',
  OPENS_UNTIL: 'openUntil',
  OPENS_TOMORROW: 'openTomorrow',
  STORE_STATUS_CLOSED_CAPS: 'CLOSED',
  EVERYDAY: 'EVERYDAY',
  DATETITLE: 'Date',
  TIMETITLE: 'Time',
  PLACE_PIN: 'placePinAtYourLocation',
  LOCATION_WARNING_MESSAGE: 'addressSuggestionHeader',
  RETRY_TEXT: 'retry',
  USE_THIS_ADDRESS_TEXT: 'useAddress',
  LIST_TEXT: 'list',
  NOTAVAILABLE: 'NOT_AVAILABLE',
  SAVED_ADDRESS: 'savedAddress',
  GEOLOCATIOM_TEXT: 'geolocation',
  PROMPT_TEXT: 'prompt',
  START_ORDER_TEXT: 'startOrder',
  BROWSE_MENU: 'browseMenu',
  ORDER_NOW: 'orderNow',
  ORDER_SCHEDULE: 'scheduleOrder',
  NO_STORE_ERROR_TEXT_TAKEAWAY: 'noStoreTextTakeaway',
  NO_STORE_ERROR_TEXT_DINEIN: 'noStoreTextDineIn',
  // Negative Error Scenirios
  TAKE_AWAY_SERVICE: 'pickup',
  DINE_IN_SERVICE: 'dine in',
  DELIVERY_SERVICE: 'delivery',
  SEARCH_THIS_AREA: 'searchThisArea',
  INDIA: 'INDIA',
  TAKEAWAY_UNAVAILABLE_WITH_CTA: 'takeAwayUnavalibleErrorTextWithCTA',
  // 'Takeaway is currently unavailable for your location. Would you like to try a different address?',
  //'takeAwayUnavalibleErrorTextWithCTA'
  TAKEAWAY_UNAVAILABLE: 'takeAwayUnavalibleErrorText',
  // 'This KFC is not accepting online orders. Please try another KFC near you or check back later.',
  // 'takeAwayUnavalibleErrorText'
  CTA1_SEARCH_DIFF_ADDRESS: 'searchDiffAddressText', //'Search for a different address',
  //'searchDiffAddressText'

  DINEIN_UNAVAILABLE: 'dineInUnavailableErrorText',
  // 'Dine-In is currently unavailable for your location. Would you like to order takeaway instead?',
  //'dineInUnavailableErrorText'
  DELIVERY_UNAVAILABLE: 'deliveryUnavailableErrorText',
  // 'Delivery is currently unavailable for your location. Would you like to order takeaway instead?',
  //'deliveryUnavailableErrorText'
  DELIVERY_UNAVAILABLE_WITH_CTA: 'deliveryUnavailableErrorTextWithCTA',
  // 'Sorry, we could not identify your address. Please be more specific with your location.',
  // 'deliveryUnavailableErrorTextWithCTA',
  CTA2_ORDER_TAKEAWAY: 'orderTakeAwayBtnText', //'Order Takeaway',
  //'orderTakeAwayBtnText'

  CTA2_VIEW_MAP_BUTTON_TEXT: 'viewMapErrorButton',
  HHMM: 'hh:mm',
  LT: 'LT',
  UNIT_NUMBER_REGEX: /^([a-zA-Z0-9/ .,]){0,40}$/,
  MAP_CARD_STORE_NAME_MAX_LENGTH: 15,
  SET_LOCATION_BUTTON_FLAG: 'SET_LOCATION_BUTTON_FLAG',
  LOCALISATION_ERROR: 'localisationError',
  API_ERROR: 'API Error',
  ORDER_NOW_CTA_TEXT: 'orderNow',
  CAN_NOT_DELIVER_ERROR_MSG: 'canNotDeliverErrorMsg',
  SAVED_ADDRESS_LIMIT: 5,
  PHONE_NUMBER: 'phoneNumber',
  DELIVERY_HOURS: 'Delivery Hours',
  TRADING_HOURS: 'Trading Hours',
  SELECTED_TIME: 'ASAP',
  ORDER_SUMMARY: 'ORDER SUMMARY',
  START_ORDER: 'Start Order',
  SUBPREMISE :'subpremise',
  SELECTED_FAV_ADDRESS:'selectedFavouriteStoreAddress',
  FORMATTED_DELIVERY_ADDRESS:'formatAddressWithDeliveryVal',
  SELECTED_LINE_ADDRESS_VAL:'selectedLineValueAddress', 
  IS_FAV_ADDRESS_SELECTED:'ifFavAddressSelected'
};
export default SEARCH_STORE_CONSTANTS;
