const DISPOSITION_CONSTANTS = {
  DISPOSITION_TYPE: {
    TYPE_DINE_IN: 'DINE-IN',
    TYPE_DELIVERY: 'DELIVERY',
    TYPE_TAKE_WAY: 'PICKUP',
  },
  DISPOSITION_ACTION: {
    SET_QR_DEEPLINK_MODE: 'SET_QR_DEEPLINK_MODE',
  },
  FIND_OPEN_KFC_TEXT: 'findOpenKfc',
  INVALID_QR_TEXT: 'invalidQRCodeError',
  CLOSED_STORE_MODAL_SUBTITLE: 'closeStoreModelSubTitle',
  TAKEAWAY_STORE_CLOSED_TEXT: 'takeawayStoreClosed',
  DINE_IN_STORE_CLOSED_TEXT: 'dineInStoreClosed',
  SWITCH_TO_TAKEAWAY_TEXT: 'switchToTakeaway',
  SWITCH_TO_DINEIN_TEXT: 'switchToDineIn',
};
export default DISPOSITION_CONSTANTS;
