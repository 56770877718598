import React, { Component } from 'react';
import { ButtonType, ContainedButton } from '../atoms/Buttons';
import { IMAGE_PATH } from '../common/ImageConstants/ImagePathUtils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
const clearLocalStorage = () => {
  window.location.replace('/');
  window.localStorage.clear();
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  /**
   * Is Used to Catch the error If any of the Child Component Has Error
   */
  componentDidCatch = (error, errorInfo) => {
    this.setState({
      error,
      errorInfo,
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    const {SOMETHING_WENT_WRONG, SOMEONE_SPILL_GRAVY, HOME} = TRANSLATE_MAPPING_KEY
    if (error && errorInfo) {
      return (
        <div className='error-box'>
          <img src={IMAGE_PATH.CartError} alt='Cart Error' />
          <h2 className={'error-headline'}>{translateWithI18Next(SOMETHING_WENT_WRONG)}</h2>
          <p>{translateWithI18Next(SOMEONE_SPILL_GRAVY)}</p>
          <ContainedButton onClick={clearLocalStorage} variant={ButtonType.tertiary}>
           {translateWithI18Next(HOME)}
          </ContainedButton>
          <details>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

// Export default
export default ErrorBoundary;
